<template>
  <div class="class-wrap">
    <div style="display: flex; align-items: center; justify-content: space-between">
      <h3 style="margin: 0">请从列表中选择班课插班</h3>
    </div>
    <a-divider />
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <span slot="action" slot-scope="text, record" style="word-break: keep-all">
        <a
          v-if="record.approvalStatus !== 'PASSED' && record.classType === 'GROUP'"
          @click="toTransfer(record.uuid, record.formClassName)"
        >
          调入
        </a>
      </span>
      <template slot="scheduleName" slot-scope="scheduleName, record">
        <span>{{ scheduleName }}</span>
        <span v-if="record.sectionNameStudent">{{ `(${record.sectionNameStudent})` }}</span>
      </template>
      <span slot="teacher" slot-scope="text, record">
        <a-popover placement="bottom">
          <template slot="content">
            <teacher-info
              :key="record.teacher.uuid"
              :teacherId="record.teacher.uuid"
              :subjectType="record.courseSectionRespDTO.data.subjectType"
            ></teacher-info>
          </template>
          <span style="cursor: pointer">
            {{ text }}
          </span>
        </a-popover>
      </span>
    </a-table>
  </div>
</template>
<script>
import { postReq, getReq } from '@/api/schedule';
import BaseList from '@/components/BaseList/index.vue';
import TeacherInfo from '@/views/schedule/teacherInfo';

const columns = [
  {
    title: '班级名称',
    dataIndex: 'formClassName',
    key: 'formClassName',
  },
  {
    title: '课节名称',
    dataIndex: 'scheduleName',
    key: 'scheduleName',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'scheduleName' },
  },
  {
    title: '开课时间(班级时区)',
    dataIndex: 'startFormClassDateTime',
    key: 'startFormClassDateTime',
  },
  {
    title: '开课时间(学生时区)',
    dataIndex: 'startStudentDateTime',
    key: 'startStudentDateTime',
  },

  {
    title: '课节人数',
    key: 'studentCount',
    dataIndex: 'studentCount',
  },
  {
    title: '授课老师',
    key: 'teacher.fullName',
    dataIndex: 'teacher.fullName',
    scopedSlots: { customRender: 'teacher' },
  },
  {
    title: '班主任',
    key: 'headmasterName',
    dataIndex: 'headmasterName',
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];
export default {
  extends: BaseList,
  components: {
    TeacherInfo,
  },
  data() {
    return {
      columns,
      searchUrl: `/api/admin/scheduling/leave/adjust/${this.$route.query.courseScheduleId}`,
      courseScheduleId: this.$route.query.courseScheduleId,
      studentId: this.$route.query.studentId,
      leaveItemId: this.$route.query.leaveItemId,
      studentTimezone: this.$route.query.studentTimezone,
    };
  },
  methods: {
    toTransfer(newScheduleId, className) {
      const $this = this;
      this.$confirmAnt({
        title: '班课插班',
        content: `确定要插入：${className} 吗？`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          $this.loading = true;
          getReq(`/api/admin/scheduling/leave/adjust/${$this.studentId}/${$this.courseScheduleId}/${newScheduleId}`, {})
            .then(() => {
              // eslint-disable-next-line no-restricted-globals
              history.go(-1);
            })
            .finally(() => {
              $this.loading = false;
            });
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.ant-form-item {
  margin-bottom: 0px;
}
.class-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fff;
  padding: 16px 20px;
}
</style>
